const routes = [
  {
    path: "/visitList",
    component: () => import("@/views/VisitList"),
    key: "/visitList",
    needLogin: true,
    tabbar: false,
    title: "上门列表",
    cache: true,
    safeArea: true,
  },
  {
    path: "/visitConfirm/:id/:name/:campus/:visitor/:time/:isTrial",
    component: () => import("@/views/VisitConfirm"),
    key: "/visitConfirm",
    needLogin: true,
    tabbar: false,
    title: "确认上门",
    cache: false,
    safeArea: true,
  },
];

export default routes;